import { useI18n } from 'vue-i18n';
import { membershipTypes } from '~/models/membership-types';

export const getMembershipTypes = () => {
  const { t } = useI18n();
  return membershipTypes.map((type) => {
    return {
      label: t(type.toLowerCase()),
      value: type.toUpperCase()
    };
  });
}
